import { _reducer as listReducer} from './slices/list'
import { _reducer as deleteReducer} from './slices/delete'
import { _reducer as editReducer} from './slices/edit'

export const eventsReducers = {
  ...listReducer,
  ...deleteReducer,
  ...editReducer,
}

export {
  fetchDispatcher as fetchEvents,
  useSelector as useEventsState,
  actions as eventsActions
} from './slices/list'

export {
  useSelector as useEventsEditState,
  fetchDispatcher as fetchEventForEdit,
  resetDispatcher as resetEventForEdit,
  saveDispatcher as saveEvent
} from './slices/edit'

export {
  useSelector as useEventsDeleteState,
  fetchDispatcher as fetchEventForDelete,
  deleteDispatcher as deleteEvent
} from './slices/delete'
