import React, {Suspense, useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {ContentRoute, Layout, LayoutSplashScreen} from "../_metronic/layout";
import ErrorsPage from "./pages/ErrorsPages/ErrorsPage";
import {useIntl} from "react-intl";
import {SITE_NAME} from "../_ae/helpers/UIHelper";
import {matchPath} from "react-router";
import {AuthLayout, ForgotPassword, Login, Logout, Registration, ResetPassword} from "./modules/Auth";
import {fetchRoutesForApp, useRoutesForAppState} from "../redux/routes";
import {ICONS} from "../_ae/components/svg";
import {EditPassword, EditProfile} from "./modules/Profile";
import {UsersDelete, UsersEdit, UsersList} from "./modules/Users";
import {RolesDelete, RolesEdit, RolesList} from "./modules/Roles";
import {MODULES, VIEWS} from "../_ae/helpers/RoutingHelpers";
import {CategoriesList, CategoryDelete, CategoryEdit} from "./modules/Categories";
import {PostDelete, PostEdit, PostsList} from "./modules/Posts";
import {EventDelete, EventEdit, EventsList} from "./modules/Events";
import {GalleryPostDelete, GalleryPostEdit, GalleryPostsList} from "./modules/GalleryPosts";
import {CitiesDelete, CitiesEdit, CitiesList} from "./modules/Cities";
import {MembersList, MemberDelete, MemberEdit} from "./modules/Members";


export const ROUTES = [
    {
        routeKey: 'AUTH.LOGIN',
        path: `/auth/login`,
        component: Login,
        context: 'auth',
        views: []
    },
    {
        routeKey: 'AUTH.REGISTER',
        path: `/auth/registration`,
        component: Registration,
        context: 'auth',
        views: []
    },
    {
        routeKey: 'AUTH.FORGOT',
        path: `/auth/password/forgot`,
        component: ForgotPassword,
        context: 'auth',
        views: []
    },
    {
        routeKey: 'AUTH.RESET',
        path: `/auth/password/reset/:token`,
        component: ResetPassword,
        context: 'auth',
        views: []
    },
    // Profile
    {
        routeKey: 'USER.PROFILE.EDIT',
        path: `/${MODULES.PROFILE}/edit`,
        svg: ICONS.EDIT,
        component: EditProfile,
        context: MODULES.PROFILE,
        views: []
    },
    {
        routeKey: 'USER.PASSWORD.EDIT',
        path: `/${MODULES.PROFILE}/password/edit`,
        svg: ICONS.EDIT,
        component: EditPassword,
        context: MODULES.PROFILE,
        views: []
    },
    // users
    {
        routeKey: 'USERS.NEW',
        path: `/${MODULES.USERS}/new`,
        svg: ICONS.ADD_USER,
        component: UsersEdit,
        context: MODULES.USERS,
        views: [VIEWS.ACTION]
    },
    {
        routeKey: 'USERS.EDIT',
        path: `/${MODULES.USERS}/:id/edit`,
        svg: ICONS.EDIT,
        component: UsersEdit,
        context: MODULES.USERS,
        views: []
    },
    {
        routeKey: 'USERS.DELETE',
        path: `/${MODULES.USERS}/:id/delete`,
        svg: ICONS.USERS_DELETE,
        component: UsersDelete,
        context: MODULES.USERS,
        views: [VIEWS.DIALOG]
    },
    {
        routeKey: 'USERS.LIST',
        path: `/${MODULES.USERS}/list`,
        svg: ICONS.USERS,
        component: UsersList,
        context: MODULES.USERS,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    // Roles
    {
        routeKey: 'ROLES.NEW',
        path: `/${MODULES.ROLES}/new`,
        svg: ICONS.PLUS,
        component: RolesEdit,
        context: MODULES.ROLES,
        views: [VIEWS.ACTION]
    },
    {
        routeKey: 'ROLES.EDIT',
        path: `/${MODULES.ROLES}/:id/edit`,
        svg: ICONS.EDIT,
        component: RolesEdit,
        context: MODULES.ROLES,
        views: []
    },
    {
        routeKey: 'ROLES.DELETE',
        path: `/${MODULES.ROLES}/:id/delete`,
        svg: ICONS.DELETE,
        component: RolesDelete,
        context: MODULES.ROLES,
        views: [VIEWS.DIALOG]
    },
    {
        routeKey: 'ROLES.LIST',
        path: `/${MODULES.ROLES}/list`,
        svg: ICONS.ROLES,
        component: RolesList,
        context: MODULES.ROLES,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    // Categories
    {
        routeKey: 'CATEGORIES.NEW',
        path: `/${MODULES.CATEGORIES}/new`,
        svg: ICONS.PLUS,
        component: CategoryEdit,
        context: MODULES.CATEGORIES,
        views: [VIEWS.ACTION]
    },
    {
        routeKey: 'CATEGORIES.EDIT',
        path: `/${MODULES.CATEGORIES}/:id/edit`,
        svg: ICONS.EDIT,
        component: CategoryEdit,
        context: MODULES.CATEGORIES,
        views: []
    },
    {
        routeKey: 'CATEGORIES.DELETE',
        path: `/${MODULES.CATEGORIES}/:id/delete`,
        svg: ICONS.DELETE,
        component: CategoryDelete,
        context: MODULES.CATEGORIES,
        views: [VIEWS.DIALOG]
    },
    {
        routeKey: 'CATEGORIES.LIST',
        path: `/${MODULES.CATEGORIES}/list`,
        svg: ICONS.CATEGORIES,
        component: CategoriesList,
        context: MODULES.CATEGORIES,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    // Posts
    {
        routeKey: 'POSTS.NEW',
        path: `/${MODULES.POSTS}/new`,
        svg: ICONS.PLUS,
        component: PostEdit,
        context: MODULES.POSTS,
        views: [VIEWS.ACTION]
    },
    {
        routeKey: 'POSTS.EDIT',
        path: `/${MODULES.POSTS}/:id/edit`,
        svg: ICONS.EDIT,
        component: PostEdit,
        context: MODULES.POSTS,
        views: []
    },
    {
        routeKey: 'POSTS.DELETE',
        path: `/${MODULES.POSTS}/:id/delete`,
        svg: ICONS.DELETE,
        component: PostDelete,
        context: MODULES.POSTS,
        views: [VIEWS.DIALOG]
    },
    {
        routeKey: 'POSTS.LIST',
        path: `/${MODULES.POSTS}/list`,
        svg: ICONS.POSTS,
        component: PostsList,
        context: MODULES.POSTS,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    // EVENTS
    {
        routeKey: 'EVENTS.NEW',
        path: `/${MODULES.EVENTS}/new`,
        svg: ICONS.PLUS,
        component: EventEdit,
        context: MODULES.EVENTS,
        views: [VIEWS.ACTION]
    },
    {
        routeKey: 'EVENTS.EDIT',
        path: `/${MODULES.EVENTS}/:id/edit`,
        svg: ICONS.EDIT,
        component: EventEdit,
        context: MODULES.EVENTS,
        views: []
    },
    {
        routeKey: 'EVENTS.DELETE',
        path: `/${MODULES.EVENTS}/:id/delete`,
        svg: ICONS.DELETE,
        component: EventDelete,
        context: MODULES.EVENTS,
        views: [VIEWS.DIALOG]
    },
    {
        routeKey: 'EVENTS.LIST',
        path: `/${MODULES.EVENTS}/list`,
        svg: ICONS.EVENTS,
        component: EventsList,
        context: MODULES.EVENTS,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    // galleryPosts
    {
        routeKey: 'GALLERY_POSTS.NEW',
        path: `/${MODULES.GALLERY_POSTS}/new`,
        svg: ICONS.PLUS,
        component: GalleryPostEdit,
        context: MODULES.GALLERY_POSTS,
        views: [VIEWS.ACTION]
    },
    {
        routeKey: 'GALLERY_POSTS.EDIT',
        path: `/${MODULES.GALLERY_POSTS}/:id/edit`,
        svg: ICONS.EDIT,
        component: GalleryPostEdit,
        context: MODULES.GALLERY_POSTS,
        views: []
    },
    {
        routeKey: 'GALLERY_POSTS.DELETE',
        path: `/${MODULES.GALLERY_POSTS}/:id/delete`,
        svg: ICONS.DELETE,
        component: GalleryPostDelete,
        context: MODULES.GALLERY_POSTS,
        views: [VIEWS.DIALOG]
    },
    {
        routeKey: 'GALLERY_POSTS.LIST',
        path: `/${MODULES.GALLERY_POSTS}/list`,
        svg: ICONS.GALLERY_POSTS,
        component: GalleryPostsList,
        context: MODULES.GALLERY_POSTS,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    // Cities
    {
        routeKey: 'CITIES.NEW',
        path: `/${MODULES.CITIES}/new`,
        svg: ICONS.PLUS,
        component: CitiesEdit,
        context: MODULES.CITIES,
        views: [VIEWS.ACTION]
    },
    {
        routeKey: 'CITIES.EDIT',
        path: `/${MODULES.CITIES}/:id/edit`,
        svg: ICONS.EDIT,
        component: CitiesEdit,
        context: MODULES.CITIES,
        views: []
    },
    {
        routeKey: 'CITIES.DELETE',
        path: `/${MODULES.CITIES}/:id/delete`,
        svg: ICONS.DELETE,
        component: CitiesDelete,
        context: MODULES.CITIES,
        views: [VIEWS.DIALOG]
    },
    {
        routeKey: 'CITIES.LIST',
        path: `/${MODULES.CITIES}/list`,
        svg: ICONS.CITIES,
        component: CitiesList,
        context: MODULES.CITIES,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
    // Members
    {
        routeKey: 'MEMBERS.NEW',
        path: `/${MODULES.MEMBERS}/new`,
        svg: ICONS.PLUS,
        component: MemberEdit,
        context: MODULES.MEMBERS,
        views: [VIEWS.ACTION]
    },
    {
        routeKey: 'MEMBERS.EDIT',
        path: `/${MODULES.MEMBERS}/:id/edit`,
        svg: ICONS.EDIT,
        component: MemberEdit,
        context: MODULES.MEMBERS,
        views: []
    },
    {
        routeKey: 'MEMBERS.DELETE',
        path: `/${MODULES.MEMBERS}/:id/delete`,
        svg: ICONS.DELETE,
        component: MemberDelete,
        context: MODULES.MEMBERS,
        views: [VIEWS.DIALOG]
    },
    {
        routeKey: 'MEMBERS.LIST',
        path: `/${MODULES.MEMBERS}/list`,
        svg: ICONS.MEMBERS,
        component: MembersList,
        context: MODULES.MEMBERS,
        views: [VIEWS.MENU, VIEWS.ACTION]
    },
]

export function Routes() {
    const dispatch = useDispatch();
    const {isAuthorized, authUser} = useSelector(({auth}) => ({
        authUser: auth.user,
        isAuthorized: auth.user != null,
    }), shallowEqual);
    const {data: routes, isLoading} = useRoutesForAppState();
    const {formatMessage} = useIntl();
    const location = useLocation();
    useEffect(() => {
        const currentRoute = routes.find(r => matchPath(location.pathname, {path: r.path}))
        let title = SITE_NAME;
        if (currentRoute) {
            title += ` | ${formatMessage({id: currentRoute.routeKey})}`
        }

        document.title = title;
    }, [routes, location.pathname])

    useEffect(() => {
        if (authUser && location.pathname !== '/logout') {
            dispatch(fetchRoutesForApp())
        }
    }, [authUser])

    //todo
    if ((isLoading || routes.length === 0) && !!authUser && location.pathname !== '/logout') {
        return <LayoutSplashScreen/>
    }

    // console.log(routes, location.pathname)

    const defaultRoute = routes.find(route => route.routeKey.includes('LIST'));

    return (
        <>
            <Switch>

                <Route path="/error" component={ErrorsPage}/>
                <Route path="/logout" component={Logout}/>

                {
                    !isAuthorized ?
                        <AuthLayout>
                            <Suspense fallback={<LayoutSplashScreen/>}>
                                <Switch>
                                    {/*FIX ME*/}
                                    <Redirect exact from="/" to="/auth"/>
                                    <Redirect exact from="/auth" to="/auth/login"/>
                                    {
                                        ROUTES
                                            .filter(route => route.context === MODULES.AUTH)
                                            .map(route => (
                                                <ContentRoute
                                                    key={route.routeKey}
                                                    {...route}
                                                />
                                            ))
                                    }
                                </Switch>
                            </Suspense>
                        </AuthLayout> :
                        <Layout>
                            <Suspense fallback={<LayoutSplashScreen/>}>
                                <Switch>
                                    <Redirect exact from="/auth/login" to="/"/>
                                    {
                                        routes.length > 0 &&
                                        <Redirect exact from="/"
                                                  to={defaultRoute ? defaultRoute.path : routes[0].path}/>
                                    }

                                    {
                                        Object
                                            .values(MODULES)
                                            .filter(key => ![MODULES.PROFILE].includes(key))
                                            .map(moduleName => (
                                                <Redirect
                                                    key={moduleName}
                                                    exact
                                                    from={`/${moduleName}`}
                                                    to={`/${moduleName}/list`}
                                                />
                                            ))
                                    }

                                    {
                                        routes
                                            .map((route, i) => {
                                                return (
                                                    <ContentRoute
                                                        key={route.routeKey}
                                                        {...route}
                                                    />
                                                )
                                            })
                                    }
                                    <Redirect to="/error"/>
                                </Switch>
                            </Suspense>
                        </Layout>

                }
            </Switch>
        </>
    );
}
