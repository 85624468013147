import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "./auth/authRedux";
import {usersReducers} from "./users";
import {routesReducers} from "./routes";
import {rolesReducers} from "./roles";
import {authReducers} from "./auth";
import {categoriesReducers} from "./categories";
import {postsReducers} from "./posts";
import {eventsReducers} from "./events";
import {galleryPostsReducers} from "./galleryPosts";
import {citiesReducers} from "./cities";
import {membersReducers} from "./members";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    ...authReducers,
    ...usersReducers,
    ...rolesReducers,
    ...routesReducers,
    ...categoriesReducers,
    ...postsReducers,
    ...eventsReducers,
    ...galleryPostsReducers,
    ...citiesReducers,
    ...membersReducers,
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
