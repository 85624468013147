import {_reducer as listReducer} from './slices/list'
import {_reducer as deleteReducer} from './slices/delete'
import {_reducer as editReducer} from './slices/edit'

export const galleryPostsReducers = {
    ...listReducer,
    ...deleteReducer,
    ...editReducer,
}

export {
    fetchDispatcher as fetchGalleryPosts,
    useSelector as useGalleryPostsState,
    actions as galleryPostsActions
} from './slices/list'

export {
    useSelector as useGalleryPostsEditState,
    fetchDispatcher as fetchGalleryPostForEdit,
    resetDispatcher as resetGalleryPostForEdit,
    saveDispatcher as saveGalleryPost
} from './slices/edit'

export {
    useSelector as useGalleryPostsDeleteState,
    fetchDispatcher as fetchGalleryPostForDelete,
    deleteDispatcher as deleteGalleryPost
} from './slices/delete'
