import React from "react";
import {AEField, AEFileField, AEHtmlField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {CategoryField} from "../../../Categories";


export const Form = ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         btnRef,
                     }) => {

    return (
        <div className="form form-label-right">
            <div className="form-group row">
                <div className={"col-lg"}>
                    <AEField
                        name="title"
                        label={'TITLE'}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className={"col-lg"}>
                    <AEField
                        type={'textarea'}
                        rows={5}
                        name="description"
                        label={'DESCRIPTION'}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg"}>
                    <AEFileField
                        name={'fileName'}
                        label={'IMAGE'}
                        // preview
                        previewPath={'/gallery_posts'}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg"}>
                    <AEFileField
                        multiple
                        name={'images'}
                        label={'IMAGES'}
                        // preview
                        previewPath={'/event_attachments'}
                    />
                </div>
            </div>
            <button
                style={{display: "none"}}
                ref={btnRef}
                onClick={handleSubmit}
            />
        </div>
    );
}

