import {_reducer as listReducer} from './slices/list'
import {_reducer as deleteReducer} from './slices/delete'
import {_reducer as editReducer} from './slices/edit'
import {_reducer as listForSelectReducer} from './slices/listForSelect'

export const citiesReducers = {
    ...listReducer,
    ...deleteReducer,
    ...editReducer,
    ...listForSelectReducer,
}

export {
    fetchDispatcher as fetchCities,
    useSelector as useCitiesState,
    actions as citiesActions
} from './slices/list'

export {
    useSelector as useCitiesEditState,
    fetchDispatcher as fetchCityForEdit,
    resetDispatcher as resetCityForEdit,
    saveDispatcher as saveCity
} from './slices/edit'

export {
    useSelector as useCitiesDeleteState,
    fetchDispatcher as fetchCityForDelete,
    deleteDispatcher as deleteCity
} from './slices/delete'


export {
    useSelector as useCitiesForSelect,
    fetchDispatcher as fetchCitiesForSelect,
    actions as citiesForSelectActions
} from './slices/listForSelect'