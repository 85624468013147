import {_reducer as membersReducer} from './slices/list'
import {_reducer as membersDeleteReducer} from './slices/delete'
import {_reducer as membersEditReducer} from './slices/edit'
import {_reducer as listForSelectReducer} from './slices/listForSelect'

export const membersReducers = {
    ...membersReducer,
    ...membersDeleteReducer,
    ...membersEditReducer,
    ...listForSelectReducer
}

export {
    fetchDispatcher as fetchMembers,
    useSelector as useMembersListState,
    actions as membersListActions
} from './slices/list'

export {
    useSelector as useMembersEditState,
    fetchDispatcher as fetchMemberForEdit,
    resetDispatcher as resetMemberForEdit,
    saveDispatcher as saveMember
} from './slices/edit'

export {
    useSelector as useMembersDeleteState,
    fetchDispatcher as fetchMemberForDelete,
    deleteDispatcher as deleteMember
} from './slices/delete'

export {
    useSelector as useMembersForSelect,
    fetchDispatcher as fetchMembersForSelect,
    actions as membersForSelectActions
} from './slices/listForSelect'