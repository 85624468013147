import { _reducer as usersReducer} from './slices/list'
import { _reducer as usersDeleteReducer} from './slices/delete'
import { _reducer as usersEditReducer} from './slices/edit'
import { _reducer as listForSelectReducer} from './slices/listForSelect'

export const usersReducers = {
  ...usersReducer,
  ...usersDeleteReducer,
  ...usersEditReducer,
  ...listForSelectReducer
}

export {
  fetchDispatcher as fetchUsers,
  useSelector as useUsersListState,
  actions as usersListActions
} from './slices/list'

export {
  useSelector as useUsersEditState,
  fetchDispatcher as fetchUserForEdit,
  resetDispatcher as resetUserForEdit,
  saveDispatcher as saveUser
} from './slices/edit'

export {
  useSelector as useUsersDeleteState,
  fetchDispatcher as fetchUserForDelete,
  deleteDispatcher as deleteUser
} from './slices/delete'

export {
  useSelector as useUsersForSelect,
  fetchDispatcher as fetchUsersForSelect,
  actions as usersForSelectActions
} from './slices/listForSelect'