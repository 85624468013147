export const MODULES = {
    USERS: 'users',
    AREAS: 'areas',
    ROUTES: 'routes',
    ROLES: 'roles',
    LOGS: 'logs',

    CATEGORIES: 'categories',
    CITIES: 'cities',
    MEMBERS: 'members',
    POSTS: 'posts',

    BANNERS: 'banners',
    EVENTS: 'events',
    GALLERY_POSTS: 'gallery_posts',

    PROFILE: 'profile',
    AUTH: 'auth'
}
export const VIEWS = {
    MENU: 'MENU',
    ACTION: 'ACTION',
    DIALOG: 'DIALOG'
}